import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Divider, List, ListItem, ListItemText, Alert, Collapse, CircularProgress } from '@mui/material';
import { Event } from '../types/Event';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

import { API_BASE_URL } from '../config';

interface Props {
  event: Event;
  onClose: () => void;
  onEventUpdated: (updatedEvent: Event) => void;
}

const JoinModal: React.FC<Props> = ({ event: initialEvent, onClose, onEventUpdated }) => {
  const [event, setEvent] = useState(initialEvent);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [spots, setSpots] = useState<number | ''>(1);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const [showParticipants, setShowParticipants] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidHKPhoneNumber = (phoneNumber: string) => {
    const hkPhoneRegex = /^([2-9][0-9]{7})$/;
    return hkPhoneRegex.test(phoneNumber);
  };

  const isFormValid = () => {
    return name.trim() !== '' && isValidHKPhoneNumber(phone) && spots !== '' && spots > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidHKPhoneNumber(phone)) {
      setError('請輸入有效的香港電話號碼（8位數字，不包含空格或符號）');
      return;
    }
    setIsLoading(true);
    try {
      console.log('發送加入請求:', { name, phone, spots });
      await axios.post(`${API_BASE_URL}/public/events/${event._id}/join`, { name, phone, spots });
      setSuccess(event.isSendWhatsapp ? '您已成功加入活動！請留意Whatsapp來自+1(347)946-6119的確認訊息。' : '您已成功加入活動！');
      
      // Fetch the updated event data
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      if (isAuthenticated) {

        const updatedEventResponse = await axios.get(`${API_BASE_URL}/events/${event._id}`, { headers });
        const updatedEvent = updatedEventResponse.data;
      
        // Update the local state with the new event data
        setEvent(updatedEvent);
        
        // Pass the updated event to the parent component
        onEventUpdated(updatedEvent);
      } else {
        const updatedEventResponse = await axios.get(`${API_BASE_URL}/public/events/${event._id}`);
        const updatedEvent = updatedEventResponse.data;
      
        // Update the local state with the new event data
        setEvent(updatedEvent);
        
        // Pass the updated event to the parent component
        onEventUpdated(updatedEvent);
      }
      // Reset form fields
      setName('');
      setPhone('');
      setSpots(1);
    } catch (err: any) {
      console.error('錯誤回應:', err.response);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message || '加入活動失敗。請稍後再試。');
      } else {
        setError('發生意外錯誤。請稍後再試。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 8);
    setPhone(value);
  };

  const handleSpotsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    if (value === '' || (Number.isInteger(value) && value >= 1 && value <= 10)) {
      setSpots(value);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const getDayOfWeek = (dateString: string): string => {
    const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>活動詳情</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          日期：{formatDate(event.date)} ({getDayOfWeek(event.date)})
        </Typography>
        <Typography variant="body1" gutterBottom>
          時間：{event.time}
        </Typography>
        <Typography variant="body1" gutterBottom>
          地點：{event.location}
        </Typography>
        <Typography variant="body1" gutterBottom>
          費用：${event.memberFee} / ${event.normalFee}
        </Typography>
        <Typography variant="body1" gutterBottom>
          參與者：{event.participants?.reduce((sum, p) => sum + (p.spots ?? 0), 0) ?? 0}/{event.maxParticipants ?? 0}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {event.otherDetails}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          參與者名單
          <Button
            size="small"
            onClick={() => setShowParticipants(!showParticipants)}
            sx={{ ml: 2 }}
          >
            {showParticipants ? '隱藏名單' : '顯示名單'}
          </Button>
        </Typography>
        <Collapse in={showParticipants}>
          <List dense>
            {event.participants && event.participants.length > 0 ? (
              event.participants.map((participant, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${participant.name} (${participant.spots} 位)`}
                    secondary={
                      <>
                        {isAuthenticated && `電話: ${participant.phone}`}
                      </>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="目前沒有參與者" />
              </ListItem>
            )}
          </List>
        </Collapse>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          加入活動
        </Typography>
        {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mt: 2, mb: 2 }}>{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            id="name"
            label="姓名"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="phone"
            label="電話號碼"
            type="tel"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={handlePhoneChange}
            required
            inputProps={{
              maxLength: 8,
              pattern: "[2-9][0-9]{7}",
            }}
            helperText="請輸入8位數香港電話號碼"
          />
          <TextField
            margin="dense"
            id="spots"
            label="參與者數量"
            type="number"
            fullWidth
            variant="outlined"
            value={spots}
            onChange={handleSpotsChange}
            required
            inputProps={{
              min: 1,
              max: 10,
            }}
            helperText="請輸入1-10之間的參與者數量"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>取消</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!isFormValid() || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : '加入'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinModal;
