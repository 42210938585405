import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { 
  Button, 
  TextField, 
  Typography, 
  Container, 
  Box, 
  Alert, 
  CircularProgress,
  Paper
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [siteName, setSiteName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length <= 8) {
      setPhone(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    // Validation
    if (password !== confirmPassword) {
      setError('密碼不匹配');
      return;
    }

    if (password.length < 6) {
      setError('密碼必須至少6個字符');
      return;
    }

    if (phone.length !== 8) {
      setError('請輸入8位數香港電話號碼');
      return;
    }

    if (!/^[2-9]\d{7}$/.test(phone)) {
      setError('請輸入有效的香港電話號碼');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/signup`, {
        username,
        password,
        siteName,
        phone
      });
      
      const { token, route } = response.data;
      login(token);
      navigate(`/${route}`);
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message || '註冊失敗');
      } else {
        setError('註冊時發生錯誤');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ 
        mt: 8, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
      }}>
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            註冊新帳戶
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="用戶名"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isLoading}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              label="網站名稱"
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
              disabled={isLoading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="電話號碼"
              value={phone}
              onChange={handlePhoneChange}
              disabled={isLoading}
              inputProps={{
                maxLength: 8,
                pattern: "[2-9][0-9]{7}"
              }}
              helperText="請輸入8位數香港電話號碼"
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="密碼"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="確認密碼"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : '註冊'}
            </Button>

            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/login')}
              disabled={isLoading}
            >
              已有帳戶？登入
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Signup;
