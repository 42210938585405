import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, Grid, Alert, Typography, List, ListItem, ListItemText, IconButton, SelectChangeEvent, AppBar, Toolbar, Box, Collapse, Switch, FormControlLabel, Link, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoneyIcon from '@mui/icons-material/Money';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Event, Participant } from '../types/Event';
import venueData from '../allVenue_BADC.json';
import { green } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';

import { API_BASE_URL } from '../config';

interface Venue {
    venueName: string;
    distCode: string;
}

interface GroupedVenues {
    [key: string]: Venue[];
}

const groupVenuesByDistrict = (venues: Venue[]): GroupedVenues => {
    return venues.reduce((acc: GroupedVenues, venue) => {
        if (!acc[venue.distCode]) {
            acc[venue.distCode] = [];
        }
        acc[venue.distCode].push(venue);
        return acc;
    }, {});
};

const groupedVenues = groupVenuesByDistrict(venueData);

interface Props {
    event: Event;
    onClose: () => void;
    onEventUpdated: (updatedEvent: Event) => void;
    onEventDeleted: (eventId: string) => void;
}

const ManageEventModal: React.FC<Props> = ({ event, onClose, onEventUpdated, onEventDeleted }) => {
    const [editedEvent, setEditedEvent] = useState<Event>({ ...event });
    const [editingParticipant, setEditingParticipant] = useState<Participant | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [showEditDelete, setShowEditDelete] = useState(false);
    const [showEventDetails, setShowEventDetails] = useState(false);
    const hours = Array.from({ length: 17 }, (_, i) => (i + 7).toString().padStart(2, '0'));
    const [isDeleting, setIsDeleting] = useState(false);
    const [broadcastMessage, setBroadcastMessage] = useState('');
    const [isBroadcasting, setIsBroadcasting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isParticipantSaving, setIsParticipantSaving] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const dialogContentRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const { name, value } = e.target;
        if (name === 'fromHour' || name === 'toHour') {
            const [fromHour, toHour] = editedEvent.time.split(' - ');
            const newTime = name === 'fromHour'
                ? `${value}:00 - ${toHour}`
                : `${fromHour} - ${value}:00`;
            setEditedEvent(prev => ({ ...prev, time: newTime }));
        } else if (name === 'isSendWhatsapp') {
            setEditedEvent(prev => ({ ...prev, isSendWhatsapp: (e.target as HTMLInputElement).checked }));
        } else {
            setEditedEvent(prev => ({ ...prev, [name]: name === 'memberFee' || name === 'normalFee' ? parseFloat(value) : value }));
        }
    };

    const handleParticipantInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditingParticipant(prev => prev ? {
            ...prev,
            [name]: name === 'spots' ? parseInt(value) : value
        } : null);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await axios.put(`${API_BASE_URL}/events/${event._id}`, editedEvent, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            setSuccess('活動已成功更新！');
            onEventUpdated(editedEvent);
            // Scroll to top after successful save
            if (dialogContentRef.current) {
                dialogContentRef.current.scrollTop = 0;
            }
        } catch (err: any) {
            console.error('Error updating event:', err);
            setError(err.response?.data?.message || '更新活動失敗請稍後再試。');
        } finally {
            setIsSaving(false);
        }
    };

    const handleSaveParticipant = async () => {
        if (!editingParticipant) return;
        setIsParticipantSaving(true);
        try {
            const response = await axios.put(
                `${API_BASE_URL}/events/${editedEvent._id}/participants/${editingParticipant._id}`,
                editingParticipant,
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
            );
            setEditedEvent(response.data.event);
            setEditingParticipant(null);
            onEventUpdated(editedEvent);
        } catch (error) {
            console.error('Error updating participant:', error);
            setError('更新參與者失敗。請稍後再試。');
        } finally {
            setIsParticipantSaving(false);
        }
    };

    const handleRemoveParticipant = async (participantId: string) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/events/${editedEvent._id}/participants/${participantId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            setEditedEvent(response.data.event);
            onEventUpdated(editedEvent);
        } catch (error) {
            console.error('Error removing participant:', error);
            setError('移除參與者失敗。請稍後再試。');
        }
    };

    const handleCheckIn = async (participantId: string) => {
        // Optimistically update the UI
        setEditedEvent(prev => ({
            ...prev,
            participants: prev.participants.map(p => 
                p._id === participantId ? { ...p, checkedIn: !p.checkedIn } : p
            )
        }));

        try {
            const response = await axios.put(`${API_BASE_URL}/events/${editedEvent._id}/participants/${participantId}/checkin`, undefined, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            // Update with the server response to ensure consistency
            setEditedEvent(response.data.event);
        } catch (error) {
            console.error('Error checking in participant:', error);
            setError('簽到失敗。請稍後再試。');
            // Revert the optimistic update
            setEditedEvent(prev => ({
                ...prev,
                participants: prev.participants.map(p => 
                    p._id === participantId ? { ...p, checkedIn: !p.checkedIn } : p
                )
            }));
        }
    };

    const handlePaid = async (participantId: string) => {
        // Optimistically update the UI
        setEditedEvent(prev => ({
            ...prev,
            participants: prev.participants.map(p => 
                p._id === participantId ? { ...p, paid: !p.paid } : p
            )
        }));

        try {
            const response = await axios.put(`${API_BASE_URL}/events/${editedEvent._id}/participants/${participantId}/paid`, undefined, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            // Update with the server response to ensure consistency
            setEditedEvent(response.data.event);
        } catch (error) {
            console.error('Error marking participant as paid:', error);
            setError('標記付款失敗。請稍後再試。');
            // Revert the optimistic update
            setEditedEvent(prev => ({
                ...prev,
                participants: prev.participants.map(p => 
                    p._id === participantId ? { ...p, paid: !p.paid } : p
                )
            }));
        }
    };

    const getWhatsAppLink = (phone: string) => {
    // Remove any non-digit characters from the phone number
        if (phone === undefined) return '';
        const cleanPhone = phone.replace(/\D/g, '');
        return `https://wa.me/852${cleanPhone}`;
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await axios.delete(`${API_BASE_URL}/events/${event._id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            setSuccess('活動已成功刪除！');
            onEventDeleted(event._id);
        } catch (err: any) {
            console.error('Error deleting event:', err);
            setError(err.response?.data?.message || '刪除活動失敗，請稍後再試。');
        } finally {
            setIsDeleting(false);
            setShowDeleteConfirmation(false);
        }
    };

    const handleBroadcast = async () => {
        if (!broadcastMessage.trim()) {
            setError('請輸入要廣播的訊息');
            return;
        }

        setIsBroadcasting(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post(`${API_BASE_URL}/events/${event._id}/broadcast`, { message: broadcastMessage }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
            setSuccess('廣播訊息發送成功');
            setBroadcastMessage('');

            if (response.status === 207) {
                setError(`廣播訊息已發送，但部分發送失敗。失敗的接收者：${response.data.failedRecipients.join(', ')}`);
            }
        } catch (err: any) {
            console.error('廣播訊息發送錯誤:', err);
            setError(err.response?.data?.message || '廣播訊息發送失敗。請稍後再試。');
        } finally {
            setIsBroadcasting(false);
        }
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={onClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="關閉"
                        disabled={isSaving || isDeleting}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        管理活動
                    </Typography>
                    <Button color="inherit" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? <CircularProgress size={24} color="inherit" /> : <SaveIcon />}
                    </Button>
                    <Button onClick={() => setShowDeleteConfirmation(true)} color="inherit" disabled={isSaving || isDeleting}>
                        <DeleteIcon />
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent ref={dialogContentRef}>
                {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mt: 2, mb: 2 }}>{success}</Alert>}

                <Button
                    onClick={() => setShowEventDetails(!showEventDetails)}
                    startIcon={showEventDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                    {showEventDetails ? '隱藏活動詳情' : '顯示活動詳情'}
                </Button>

                <Collapse in={showEventDetails}>
                    <form>
                        <TextField
                            margin="dense"
                            name="date"
                            label="日期"
                            type="date"
                            fullWidth
                            variant="outlined"
                            value={editedEvent.date}
                            onChange={handleInputChange}
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="from-hour-label">開始時間</InputLabel>
                                    <Select
                                        labelId="from-hour-label"
                                        name="fromHour"
                                        value={editedEvent.time.split(' - ')[0].split(':')[0]}
                                        label="開始時間"
                                        onChange={handleInputChange}
                                        required
                                    >
                                        {hours.map((hour) => (
                                            <MenuItem key={hour} value={hour}>
                                                {hour}:00
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="to-hour-label">結束時間</InputLabel>
                                    <Select
                                        labelId="to-hour-label"
                                        name="toHour"
                                        value={editedEvent.time.split(' - ')[1].split(':')[0]}
                                        label="結束時間"
                                        onChange={handleInputChange}
                                        required
                                    >
                                        {hours.map((hour) => (
                                            <MenuItem key={hour} value={hour}>
                                                {hour}:00
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="venue-label">場地</InputLabel>
                            <Select
                                labelId="venue-label"
                                name="location"
                                value={editedEvent.location}
                                label="場地"
                                onChange={handleInputChange}
                                required
                            >
                                {Object.entries(groupedVenues).map(([distCode, venues]) => [
                                    <MenuItem key={distCode} disabled style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                        {distCode}
                                    </MenuItem>,
                                    ...venues.map((venue) => (
                                        <MenuItem key={venue.venueName} value={venue.venueName}>
                                            {venue.venueName}
                                        </MenuItem>
                                    ))
                                ])}
                            </Select>
                        </FormControl>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    margin="dense"
                                    name="maxParticipants"
                                    label="最大參與人數"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={editedEvent.maxParticipants}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    margin="dense"
                                    name="memberFee"
                                    label="會員費用"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={editedEvent.memberFee}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    margin="dense"
                                    name="normalFee"
                                    label="非會員費用"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={editedEvent.normalFee}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            margin="dense"
                            name="otherDetails"
                            label="其他詳情"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={editedEvent.otherDetails || ''}
                            onChange={handleInputChange}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editedEvent.isSendWhatsapp}
                                    onChange={handleInputChange}
                                    name="isSendWhatsapp"
                                    color="primary"
                                />
                            }
                            label={`參加者加入時自動發送 WhatsApp 訊息`}
                        />
                        <TextField
                            margin="dense"
                            name="sendDetails"
                            label="Whatsapp 詳情"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={editedEvent.sendDetails || ''}
                            onChange={handleInputChange}
                        />
                    </form>
                </Collapse>

                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    參與者名單
                    <Button
                        size="small"
                        onClick={() => setShowEditDelete(!showEditDelete)}
                        sx={{ ml: 2, mb: 0 }}
                    >
                        {showEditDelete ? '隱藏編輯' : '顯示編輯'}
                    </Button>
                </Typography>
                <List sx={{ py: 0 }}>
                    {editedEvent.participants.map((participant) => (
                        <ListItem
                            key={participant._id}
                            alignItems="flex-start"
                            sx={{
                                flexDirection: 'column',
                                py: 2,
                                borderBottom: '1px solid #e0e0e0',
                                '&:last-child': { borderBottom: 'none' }
                            }}
                        >
                            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <IconButton
                                    aria-label="check-in"
                                    onClick={() => handleCheckIn(participant._id)}
                                    sx={{ mr: 1, color: participant.checkedIn ? green[500] : 'default' }}
                                >
                                    <CheckCircleOutlineIcon fontSize="large" />
                                </IconButton>
                                <ListItemText
                                    primary={`${participant.name} (${participant.spots} 位) | 費用: ${participant.fee}`}
                                    secondary={
                                        <Link href={getWhatsAppLink(participant.phone)} target="_blank" rel="noopener noreferrer" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <WhatsAppIcon sx={{ mr: 0.5 }} />
                                        {participant.phone}
                                        </Link>
                                    }
                                />
                                <IconButton
                                    aria-label="paid"
                                    onClick={() => handlePaid(participant._id)}
                                    sx={{ color: participant.paid ? green[500] : 'default' }}
                                >
                                    <MoneyIcon fontSize="large" />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                {showEditDelete && (
                                    <Box>
                                        <IconButton aria-label="edit" onClick={() => setEditingParticipant(participant)}>
                                            <EditIcon fontSize="large" />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => handleRemoveParticipant(participant._id)}>
                                            <DeleteIcon fontSize="large" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                        </ListItem>
                    ))}
                </List>

                <Dialog open={!!editingParticipant} onClose={() => setEditingParticipant(null)}>
                    <DialogTitle>編輯參與者</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            name="name"
                            label="姓名"
                            fullWidth
                            value={editingParticipant?.name || ''}
                            onChange={handleParticipantInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="phone"
                            label="電話"
                            fullWidth
                            value={editingParticipant?.phone || ''}
                            onChange={handleParticipantInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="spots"
                            label="人數"
                            type="number"
                            fullWidth
                            value={editingParticipant?.spots || ''}
                            onChange={handleParticipantInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="fee"
                            label="費用"
                            type="number"
                            fullWidth
                            value={editingParticipant?.fee || ''}
                            onChange={handleParticipantInputChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditingParticipant(null)} disabled={isParticipantSaving}>取消</Button>
                        <Button onClick={handleSaveParticipant} disabled={isParticipantSaving}>
                            {isParticipantSaving ? <CircularProgress size={24} /> : '保存'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        廣播訊息
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={broadcastMessage}
                        onChange={(e) => setBroadcastMessage(e.target.value)}
                        placeholder="輸入給所有參加者的廣播訊息，例如：請於11月1日早上9時到屯門大興羽毛球場1號場見。"
                        disabled={isBroadcasting}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={isBroadcasting ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                        onClick={handleBroadcast}
                        disabled={isBroadcasting}
                        sx={{ mt: 2 }}
                    >
                        {isBroadcasting ? '傳送中...' : '傳送訊息'}
                    </Button>
                </Box>
            </DialogContent>
            <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
                <DialogTitle>確認刪除</DialogTitle>
                <DialogContent>
                    <Typography>您確定要刪除這個活動嗎？此操作無法撤銷。</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirmation(false)} disabled={isDeleting}>取消</Button>
                    <Button onClick={handleDelete} color="error" autoFocus disabled={isDeleting}>
                        {isDeleting ? <CircularProgress size={24} /> : '刪除'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

export default ManageEventModal;
