import React, { useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid2,
  Alert,
  ListSubheader,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import venueData from '../allVenue_BADC.json';
import CloseIcon from '@mui/icons-material/Close';
import { API_BASE_URL } from '../config';

interface Venue {
  venueName: string;
  distCode: string;
}

interface GroupedVenues {
  [key: string]: Venue[];
}

const groupVenuesByDistrict = (venues: Venue[]): GroupedVenues => {
  return venues.reduce((acc: GroupedVenues, venue) => {
    if (!acc[venue.distCode]) {
      acc[venue.distCode] = [];
    }
    acc[venue.distCode].push(venue);
    return acc;
  }, {});
};

const groupedVenues = groupVenuesByDistrict(venueData);

interface Props {
  onClose: () => void;
  onEventAdded: () => void;
}

const AddEventModal: React.FC<Props> = ({ onClose, onEventAdded }) => {
  const [date, setDate] = useState<string>('');
  const [fromTime, setFromTime] = useState<string>('');
  const [toTime, setToTime] = useState<string>('');
  const [location, setLocation] = useState('');
  const [maxParticipants, setMaxParticipants] = useState('');
  const [memberFee, setMemberFee] = useState('');
  const [normalFee, setNormalFee] = useState('');
  const [otherDetails, setOtherDetails] = useState('');
  const [sendDetails, setSendDetails] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!date || !fromTime || !toTime || !location) {
      setError('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    try {
      const formattedTime = `${fromTime} - ${toTime}`;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      await axios.post(`${API_BASE_URL}/events`, {
        date,
        time: formattedTime,
        location,
        maxParticipants: parseInt(maxParticipants, 10),
        memberFee: parseFloat(memberFee),
        normalFee: parseFloat(normalFee),
        otherDetails,
        sendDetails
      }, { headers });

      onEventAdded();
      onClose();
    } catch (error) {
      console.error('Error adding event:', error);
      setError('Failed to add the event. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const hours = Array.from({ length: 16 }, (_, i) => (i + 8).toString().padStart(2, '0'));

  const handleTimeChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setter(newValue + ':00');
  };

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            新增活動
          </Typography>
          <Button 
            autoFocus 
            color="inherit" 
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : '儲存'}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <TextField
              type="date"
              label="日期"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              disabled={isLoading}
            />
          </Grid2>
          <Grid2 size={6}>
            <TextField
              select
              label="開始時間"
              value={fromTime.split(':')[0]}
              onChange={handleTimeChange(setFromTime)}
              fullWidth
              margin="normal"
              disabled={isLoading}
            >
              {hours.map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}:00
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          <Grid2 size={6}>
            <TextField
              select
              label="結束時間"
              value={toTime.split(':')[0]}
              onChange={handleTimeChange(setToTime)}
              fullWidth
              margin="normal"
              disabled={isLoading}
            >
              {hours.map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}:00
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          <Grid2 size={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>地點</InputLabel>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="地點"
                disabled={isLoading}
              >
                {Object.entries(groupedVenues).map(([distCode, venues]) => [
                  <ListSubheader key={distCode}>{distCode}</ListSubheader>,
                  ...venues.map(venue => (
                    <MenuItem key={venue.venueName} value={venue.venueName}>
                      {venue.venueName}
                    </MenuItem>
                  ))
                ]).flat()}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <TextField
              margin="normal"
              label="最大參與人數"
              type="number"
              fullWidth
              value={maxParticipants}
              onChange={(e) => setMaxParticipants(e.target.value)}
              disabled={isLoading}
            />
          </Grid2>
          <Grid2 size={4}>
            <TextField
              margin="normal"
              label="優惠費用"
              type="number"
              fullWidth
              value={memberFee}
              onChange={(e) => setMemberFee(e.target.value)}
              disabled={isLoading}
            />
          </Grid2>
          <Grid2 size={4}>
            <TextField
              margin="normal"
              label="費用"
              type="number"
              fullWidth
              value={normalFee}
              onChange={(e) => setNormalFee(e.target.value)}
              disabled={isLoading}
            />
          </Grid2>
          <Grid2 size={12}>
            <TextField
              margin="normal"
              label="其他詳情"
              multiline
              rows={4}
              fullWidth
              value={otherDetails}
              onChange={(e) => setOtherDetails(e.target.value)}
              disabled={isLoading}
            />
          </Grid2>
          <Grid2 size={12}>
            <TextField
              margin="normal"
              label="Whatsapp 詳情"
              multiline
              rows={4}
              fullWidth
              value={sendDetails}
              onChange={(e) => setSendDetails(e.target.value)}
              disabled={isLoading}
            />
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventModal;
