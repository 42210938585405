import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Container, Box, Typography, Link } from '@mui/material';
import EventList from './components/EventList';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import Signup from './components/Signup';
import SiteManagement from './components/SiteManagement';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a9d0f7',
    },
    secondary: {
      main: '#b0bec5',
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const { isAuthenticated } = useAuth();
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Container maxWidth="sm" sx={{
          mb: 4, 
        }}>
          <Routes>
            <Route path="/" element={<h1>Coming Soon</h1>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/:route" element={<EventList />} />
            <Route path="/:route/site-management" element={<SiteManagement />} />
          </Routes>
        </Container>
        <Box 
            component="footer" 
            sx={{
              py: 2,
              textAlign: 'center',
              bgcolor: 'background.paper'
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Created by{' '}
              <Link 
                href="https://matchpoint.hk" 
                target="_blank" 
                rel="noopener noreferrer"
                color="primary"
                sx={{ textDecoration: 'none' }}
              >
                MatchPoint
              </Link>
              {!isAuthenticated && (
                <>
                  {' '}|{' '}
                  <RouterLink 
                    to="/login" 
                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                  >
                    登入
                  </RouterLink>
                </>
              )}
            </Typography>
          </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;