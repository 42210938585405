import React, { createContext, useContext, useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any | null;
}

type AuthAction =
  | { type: 'LOGIN_SUCCESS'; payload: any }
  | { type: 'LOGOUT' }
  | { type: 'AUTH_ERROR' }
  | { type: 'LOADING' };

interface AuthContextType extends AuthState {
  login: (token: string) => Promise<void>;
  logout: () => void;
  checkAuth: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Check if token exists in localStorage for initial state
const token = localStorage.getItem('token');
const initialState: AuthState = {
  isAuthenticated: !!token, // Set initial authentication based on token existence
  isLoading: !!token, // Only start loading if there's a token to verify
  user: null,
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case 'LOGOUT':
    case 'AUTH_ERROR':
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };
    case 'LOADING':
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch({ type: 'AUTH_ERROR' });
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/auth/check-token`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.data) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      } else {
        dispatch({ type: 'AUTH_ERROR' });
      }
    } catch (error) {
      console.error('Auth check error:', error);
      dispatch({ type: 'AUTH_ERROR' });
    }
  }, []);

  const login = async (token: string) => {
    try {
      localStorage.setItem('token', token);
      const response = await axios.get(`${API_BASE_URL}/auth/check-token`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      } else {
        dispatch({ type: 'AUTH_ERROR' });
      }
    } catch (error) {
      console.error('Login error:', error);
      dispatch({ type: 'AUTH_ERROR' });
    }
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  // Check auth status when the app loads
  useEffect(() => {
    if (token) {
      checkAuth();
    }
  }, [checkAuth]);

  return (
    <AuthContext.Provider value={{ ...state, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};